<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Profile Details</h3>
      </div>
      <!--end::Card title-->

      <!--begin::Action-->

      <!-- <router-link
        to="/account/settings"
        class="btn btn-primary align-self-center"
        >Edit Profile</router-link
      > -->

      <!--end::Action-->
    </div>
    <!--begin::Card header-->

    <!--begin::Card body-->
    <div class="card-body p-9">
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Name</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{organization.name}}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">ID</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.id}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Contact</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.contact}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">GST Number</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.gstNo}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">PAN Number</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.panNo}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">CIN number</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.cinNo}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">City</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.city}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">State</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.state}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">ZIP Code</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.zipCode}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Country of business</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.countryOfBusiness}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Address</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.address}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Tele Country Code</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.teleCountryCode}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Mobile Number</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.mobileNumber}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Created Date</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.createdDate}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Modified Date</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.modifiedDate}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">To Date</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{organization.toDate}}</span>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">From date</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{organization.fromDate}}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      
      
    </div>
    <!--end::Card body-->
  </div>
  <!--end::details View-->

  <!--begin::Row-->
  <div class="row gy-10 gx-xl-10">
    <!--begin::Col-->
    <div class="col-xl-6">
      <KTChartWidget1
        widget-classes="card-xxl-stretch mb-5 mb-xl-10"
      ></KTChartWidget1>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-6">
      <KTListWidget1
        widget-classes="card-xxl-stretch mb-5 mb-xl-10'"
      ></KTListWidget1>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->

  <!--begin::Row-->
  <div class="row gy-10 gx-xl-10">
    <!--begin::Col-->
    <div class="col-xl-6">
      <KTListWidget5
        widget-classes="card-xxl-stretch mb-5 mb-xl-10"
      ></KTListWidget5>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-6">
      <KTTableWidget5
        widget-classes="card-xxl-stretch mb-5 mb-xl-10"
      ></KTTableWidget5>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->
</template>

<script>
import { defineComponent } from "vue";
import KTChartWidget1 from "@/components/widgets/charts/Widget1.vue";
import KTListWidget5 from "@/components/widgets/lists/Widget5.vue";
import KTTableWidget5 from "@/components/widgets/tables/Widget5.vue";
import KTListWidget1 from "@/components/widgets/lists/Widget1.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import DataLayer from "@/core/services/DataLayer"
import Globals from "@/views/common/Globals.vue"


export default defineComponent({
  name: "organizationProfile",
  mixins: [Globals],
  components: {
    KTChartWidget1,
    KTListWidget5,
    KTTableWidget5,
    KTListWidget1,
  },
  data()
  {
      return{
          organization:{
              "name": "",
              "id": "",
              "email": "",
              "contact": "",
              "gstNo": "",
              "panNo": "",
              "cinNo": "",
              "city": "",
              "state": "",
              "zipCode": "",
              "countryOfBusiness": "",
              "address": "",
              "teleCountryCode": "",
              "mobileNumber": "",
              "toDate": "",
              "fromDate": "",
              "createdDate": "",
              "modifiedDate": ""
          },
      }
  },
  methods:
  {
    async getProfileData()
    {
        let response = await DataLayer.getOrgProfileData()
      
        this.organization.name = response.data.data['name']
        // this.organization.name = this.organizationData.name
        this.organization.id = response.data.data['id']
        this.organization.email = response.data.data['email']
        this.organization.contact = response.data.data['contact']
        this.organization.gstNo = response.data.data['gstNo']
        this.organization.panNo = response.data.data['panNo']
        this.organization.cinNo = response.data.data['cinNo']
        this.organization.city = response.data.data['city']
        this.organization.state = response.data.data['state']
        this.organization.zipCode = response.data.data['zipCode']
        this.organization.countryOfBusiness = response.data.data['countryOfBusiness']
        this.organization.address = response.data.data['addressLine1'] + " " + response.data.data['addressLine2'] + " " + response.data.data['addressLine3']
        this.organization.teleCountryCode = response.data.data['teleCountryCode']
        this.organization.mobileNumber = response.data.data['mobileNumber']
        this.organization.toDate = this.getDateInUtc(response.data.data['toDate'])
        this.organization.fromDate = this.getDateInUtc(response.data.data['fromDate'])
        this.organization.createdDate = this.getDateInUtc(response.data.data['createdDate'])
        this.organization.modifiedDate = this.getDateInUtc(response.data.data['modifiedDate'])
    },
    // async getProfileDataa()
    // {
    //   let response = await DataLayer.getProfileData()
    //   console.log("here response",response.data.data['addressLine1'])
    //   console.log("entire data",response.data)
    //   console.log("type",typeof(response.data.data))
      
    // }
  },
  mounted()
  {
    setCurrentPageBreadcrumbs("Organization Profile", ["Account"]);
    this.getProfileData();
  },
});
</script>
